import React, { useEffect, useRef, useState } from 'react';
import { Backdrop, Box, Button, ButtonGroup, Card, CardContent, CardMedia, Fab, Grid, IconButton, Skeleton, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { imageSources } from '../common/imageSources';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import useAuth from '../hooks/useAuth';
import SeriesButtonGroupComponent from '../component/homepage/series-button-group.component';
import SeriesCarouselComponent from '../component/homepage/series-carousel.component';
import FooterComponent from "../layout/footer.component";
import useWidth from '../hooks/useWidth';
import { useNavigate } from "react-router-dom";
import { log } from "../console-config.log";
import gemLogo from "../images/final_gem.png"
import CloseIcon from '@mui/icons-material/Close';
import MinimizeIcon from '@mui/icons-material/Minimize';
import MaximizeIcon from '@mui/icons-material/Maximize';
import SubscribeFormComponent from '../component/homepage/subscribe-form.component';
import MobileSubscribeFormComponent from '../component/homepage/mobile-subscribe-form.component';

const HomeScreenV2 = () => {

    const theme = useTheme();
    const breakpoints = useWidth();
    const navigate = useNavigate();

    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isLgScreen = useMediaQuery(theme.breakpoints.down('lg'));


    const [hoveredImage, setHoveredImage] = useState(null);
    const [isImageLoaded, setLoadedImages] = useState(new Array(imageSources.length).fill(false));
    const [imageTitleHovered, setImageTitleHovered] = useState(null);

    const handleImageLoad = (index) => {
        setLoadedImages(prevState => {
            const newState = [...prevState];
            newState[index] = true;
            return newState;
        });
    }

    const [startX, setStartX] = useState(0); // State to track start position of drag
    const [currentX, setCurrentX] = useState(0); // State to track current position of drag
    const [isDragging, setIsDragging] = useState(false); // State to track if dragging is in progress

    let clickTimeout;

    const handleMouseDown = (event) => {
        clearTimeout(clickTimeout); // Clear any previous timeout
        setIsDragging(false);

        // Set isDragging to true after a delay if click is held
        clickTimeout = setTimeout(() => {
            setIsDragging(true);
        }, 300); // Change the delay time as needed (in milliseconds)

        setStartX(event.clientX);
    }

    const handleMouseMove = (event) => {
        if (isDragging) {
            const deltaX = event.clientX - startX;
            setCurrentX(currentX + deltaX); // Adjust currentX instead of setting to deltaX directly
            setStartX(event.clientX);
        }
    }

    const handleMouseUp = (event) => {
        setTimeout(() => {
            setIsDragging(false);
        }, 300);
    }

    const handleTouchStart = (event) => {
        clearTimeout(clickTimeout); // Clear any previous timeout
        setIsDragging(false);

        // Set isDragging to true after a delay if click is held
        clickTimeout = setTimeout(() => {
            setIsDragging(true);
        }, 300); // Change the delay time as needed (in milliseconds)

        setStartX(event.touches[0].clientX);
    };

    const handleTouchMove = (event) => {
        if (isDragging) {
            const deltaX = event.touches[0].clientX - startX;
            setCurrentX(currentX + deltaX); // Adjust currentX instead of setting to deltaX directly
            setStartX(event.touches[0].clientX);
        }
    };

    const handleTouchEnd = () => {
        clearTimeout(clickTimeout);
        setIsDragging(false);
    };

    const isDarkmode = JSON.parse(localStorage.getItem("darkMode"));

    const handleRightClick = (event) => {
        event.preventDefault();
    }

    const handleImageTitleHover = (hoveredIndex) => {
        setImageTitleHovered(hoveredIndex);

        const scope = document.getElementById('carousel-scope');

        const imagesPerSide = 9;
        const totalImages = imageSources.length;
        const anglePerImage = 360 / totalImages;
        const totalVisibleImages = imagesPerSide * 2 + 1;
        const centerIndex = Math.floor(totalVisibleImages / 2);
        const targetAngle = (centerIndex - hoveredIndex) * anglePerImage;


        const currentTransform = getComputedStyle(scope).transform;
        const currentRotation = extractRotation(currentTransform);
        const currentAngle = parseFloat(currentRotation);

        // Calculate the shortest rotation path
        let rotationDifference = targetAngle - currentAngle;
        if (rotationDifference > 180) {
            rotationDifference -= 360;
        } else if (rotationDifference < -180) {
            rotationDifference += 360;
        }

        const duration = 500; // Adjust duration as needed
        const startTime = performance.now();

        const rotateCarousel = (timestamp) => {
            const elapsed = timestamp - startTime;
            const progress = Math.min(elapsed / duration, 1); // Ensure progress is capped at 1

            const nextAngle = currentAngle + rotationDifference * progress;
            scope.style.transform = `perspective(1000px) rotateY(${nextAngle}deg)`;

            if (progress < 1) {
                requestAnimationFrame(rotateCarousel);
            }
        };

        requestAnimationFrame(rotateCarousel);
    }

    // Function to extract rotation angle from the transform matrix
    function extractRotation(matrix) {
        const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ');
        const a = matrixValues[0];
        const b = matrixValues[1];
        const angle = Math.round(Math.atan2(b, a) * (180 / Math.PI));
        return angle >= 0 ? angle : 360 + angle;
    }


    const handleImageTitleHoverLeave = () => {
        setImageTitleHovered(null);

        const scope = document.getElementById('carousel-scope');

        scope.style.transition = 'transform 2s ease-in-out';
        scope.style.transform = `perspective(1000px) rotateY(${currentX / 13}deg)`;
        scope.style.setProperty('--start-angle', `${currentX / 13}deg`);
    }

    // Function to detect zoom level
    function detectZoom() {
        var ratio = 0,
            screen = window.screen,
            ua = navigator.userAgent.toLowerCase();

        if (window.devicePixelRatio !== undefined) {
            ratio = window.devicePixelRatio;
        }
        else if (~ua.indexOf('msie')) {
            if (screen.deviceXDPI && screen.logicalXDPI) {
                ratio = screen.deviceXDPI / screen.logicalXDPI;
            }
        }
        else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
            ratio = window.outerWidth / window.innerWidth;
        }

        if (ratio) {
            return Math.round(ratio * 100);
        }

        return 100; // Default to 100% zoom if unable to detect
    }

    // Function to apply styles based on zoom level
    function applyStylesForZoomLevel() {
        var zoomLevel = detectZoom();
        if (zoomLevel === 125) {
            // Apply styles for 125% zoom
            document.body.classList.add('zoom-125');

        } else if (zoomLevel === 150) {
            document.body.classList.add('zoom-150');

        } else {
            // Remove styles if zoom level is not 125%
            document.body.classList.remove('zoom-125');
            document.body.classList.remove('zoom-150');
        }
    }

    // Apply styles initially and listen for resize events to reapply if needed
    applyStylesForZoomLevel();
    window.addEventListener('resize', applyStylesForZoomLevel);


    const [minimized, setMinimized] = useState(false);

    const toggleMinimize = () => {
        setMinimized(!minimized);
    };

    const [displayContent, setDisplayContent] = useState(false);

    const toggleDisplayContent = () => {
        console.log("herexxx")
        setDisplayContent(!displayContent);
    };

    return (
        <div className="containerHome" >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'GucciSans',
                        fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 30 : 60,
                        margin: 0,
                        lineHeight: 1.6,
                        fontWeight: 600,
                        textAlign: 'center',
                        mt: 1,
                        color: "#fff",
                        letterSpacing: (breakpoints == "xs" || breakpoints == "sm") ? {} : "4px"
                    }}
                >
                    {/* Gem x Memory */}
                    Be a GemMaster
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Typography sx={{
                    fontFamily: "GucciSans", mt: "3px", fontSize: (breakpoints == "xs" || breakpoints == "sm") ? "14px" : '1.4rem', fontWeight: 500, textAlign: 'center',
                    color: '#fff'
                }}>
                    Capture life's precious memories
                </Typography>
            </Box>



            <SeriesCarouselComponent
                handleMouseDown={handleMouseDown}
                handleMouseMove={handleMouseMove}
                handleMouseUp={handleMouseUp}
                handleTouchEnd={handleTouchEnd}
                handleTouchMove={handleTouchMove}
                handleTouchStart={handleTouchStart}
                imageTitleHovered={imageTitleHovered}
                imageSources={imageSources}
                isImageLoaded={isImageLoaded}
                handleRightClick={handleRightClick}
                handleImageLoad={handleImageLoad}
                setHoveredImage={setHoveredImage}
                isDragging={isDragging}
                currentX={currentX}
                isDarkmode={isDarkmode}
                hoveredImage={hoveredImage}
            />


            <SeriesButtonGroupComponent
                imageSources={imageSources}
                isSmScreen={isSmScreen}
                hoveredImage={hoveredImage}
                handleImageTitleHover={handleImageTitleHover}
                handleImageTitleHoverLeave={handleImageTitleHoverLeave}
                isDarkmode={isDarkmode}
                imageTitleHovered={imageTitleHovered}
            />

            {(breakpoints == "xs" || breakpoints == "sm") &&
                <Typography onClick={(e) => navigate("/series/alpha")} className="blink" sx={{
                    cursor: 'pointer', fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 16 : 16, textTransform: "none", fontFamily: "GucciSans",
                    color: "#fff", fontWeight: 700,
                    mt: (breakpoints == "xs") ? "11px" : "90px",
                }}>
                    ALPHA is LIVE!
                </Typography>
            }

            <div style={{
                marginTop: (breakpoints == "xl") ? "45px"
                    :
                    (breakpoints == "xs") ? "0px"
                        :
                        (breakpoints == "sm" || breakpoints == "md") ? "0px"
                            :
                            "16px",
                marginBottom: "8px"
            }}>
                <FooterComponent />
            </div>

            {(breakpoints == "xs" || breakpoints == "sm") ?
                <MobileSubscribeFormComponent 
                    displayContent={displayContent}
                    toggleDisplayContent={toggleDisplayContent}
                    breakpoints={breakpoints}
                    setDisplayContent={setDisplayContent}
                />
                    
            :
                <SubscribeFormComponent 
                    minimized={minimized}
                    toggleMinimize={toggleMinimize}
                    breakpoints={breakpoints}
                    setMinimized={setMinimized}
                />
            }

           

        </div>
    );
};

export default HomeScreenV2;
